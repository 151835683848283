import Glide  from '@glidejs/glide'

const items = document.querySelectorAll('.image-carousel')
const itemArray = [...items];

itemArray.forEach(item => {
    new Glide(item, {
        type: 'carousel',
        perView: 3,
        startAt: 0,
        focusAt: 'center',
        gap: 32,
        hoverpause: true,
        autoplay: 3000,

        breakpoints: {
            1024: {
                perView: 1,
                peek: { before: 50, after: 50 },
                gap: 12,
            }
        }
    }).mount()
})
