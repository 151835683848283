import Panzoom from '@panzoom/panzoom';

const image = document.getElementById('cmcMap');
const container = document.getElementById('mapContainer');

let panZoom;

function initializeMap() {
	if(window.innerWidth >= 768){
		initializePanZoom()
	}
}

function initializePanZoom() {
    if (container && image) {
        const svgBox = image.getBBox();
        const scaleX = container.offsetWidth / svgBox.width;
        const scaleY = container.offsetHeight / svgBox.height;
		const initialScale = Math.min(scaleX, scaleY);

		const options = {
			startScale: initialScale,
			minScale: initialScale,
			maxScale: 3,
			overflow: 'visible'
		}

		if (panZoom) {
            panZoom.setOptions(options);
            panZoom.reset();
        } else {
            panZoom = Panzoom(image, options);
        }

		document.getElementById('zoomIn').addEventListener('click', panZoom.zoomIn);
		document.getElementById('zoomOut').addEventListener('click', panZoom.zoomOut);
	}
}

initializeMap();

const tab = document.getElementById('participantListTab');
const cont = document.getElementById('participantListContainer');

if(tab && cont){
	tab.addEventListener('click', function(event) {
		event.stopPropagation();
		cont.classList.toggle('map__tabs--selected');
	});

	document.addEventListener('click', function() {
		cont.classList.remove('map__tabs--selected');
	});

	cont.addEventListener('click', function(event) {
		event.stopPropagation();
	});
}
