const burger = document.querySelector('#header .burger')
const close = document.querySelector('#header .close')
const mobileNav = document.getElementById('header__mobile-nav')
console.log('loaded')
burger.addEventListener('click', () => {
    console.log('burger')
    mobileNav.classList.add('header__mobile-nav--in')
})

close.addEventListener('click', () => {
    mobileNav.classList.remove('header__mobile-nav--in')
})
