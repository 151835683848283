const items = document.querySelectorAll('.pullout')
const itemArray = [...items]

const layout = (item) => {
    const imageContainer = item.querySelector('.pullout__image')
    const before = window.getComputedStyle(item, '::before')
    const width = parseFloat(before.width)
    const property = item.classList.contains('pullout--right') ? 'marginRight' : 'marginLeft'

    imageContainer.style[property] = `-${width}px`
}

itemArray.forEach(item => {
    layout(item)

    window.addEventListener('resize', () => {
        layout(item)
    })
})
